/*  CSS RESET
    source: https://meyerweb.com/eric/tools/css/reset/
*/

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/*CSS RESET END*/

/*MY CSS START*/
* {
    box-sizing: border-box;
 }
 input:focus,button:focus, .hide-outline {
    outline:0 !important;
}

/* @font-face {
    font-family: Segoe;
    src: url(sansation_light.woff);
} */


body{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    user-select: none;
    -ms-user-select:none;
}

.shadow {
    border-radius: 1px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.2);
  }

/* .menu{
    position: absolute;    
    width: 200px;
    height: 300px;
    background-color: #fff;   
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(16,22,26,.1), 0 2px 4px rgba(16,22,26,.2), 0 8px 24px rgba(16,22,26,.2);
    z-index: 1801;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.2);
    
} */
/* .overlay{
    height: 100vh;
    width: 100vw;
    z-index: 1800;
    background-color: transparent;
    position: absolute;
    top: 0px;
    left: 0px;
} */

.animated {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.07, 1.03, 0.62, .92);
    /* animation-timing-function: linear */
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }

  @keyframes slideInRight {
    from {
      transform: translate3d(100%, 0, 0);
      visibility: visible;
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInRight {
    animation-name: slideInRight;
  }
  
  .fadeIn {
    animation-name: fadeIn;
  }

#story-container{
    /* background-color: #f4f4f4; */
    position: relative;
    -ms-user-select: text;
    user-select:text;
}
#story-container img,#story-container video, #story-container   iframe{
    max-width: 100%;
    display:block;
    margin: 15px auto;
}
#story-container img{
    /* height: 100%; */
    max-height: max-content;
}

#story-container a{
    color: #005C9C;
    text-decoration: none;
    font-weight: 400;
    border-bottom: 1px solid #ccc;
}
#story-container p{
    margin-top: 20px;
}
#story-container h2{
    font-size: 1.2em;
    margin: 10px 0px;
    font-weight: 600;
}
.wallpaper{
    position: relative;
    z-index:0;
}
/* .wallpaper::before{
    content: "";
    height: 90%;
    width: 90%;
    position: absolute;
    top: 10px;
    left: 10px;
    filter: blur(10px);
    z-index: -1;
} */
.wallpaper::after{
    filter: blur(0px);
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    background-color: rgba(0,0,0,0.5);
}

select {
    padding:7px;
    width:100px;
    border:1px solid #bbb;
    outline:0;
    border-radius:3px;
    text-align:center;
    font-size: 0.9em;
    appearance: none;

    transition:0.3s ease all;
    background:url("data:image/svg+xml;utf8,<svg fill='#777' xmlns='http://www.w3.org/2000/svg' width='50px' height='50px'><polyline points='46.139,15.518 25.166,36.49 4.193,15.519'/></svg>");
    background-repeat:no-repeat;
    background-position: right 10px top 10px;
    background-size: 16px;
  }



  /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .2s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 15px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .2s;
}

input:checked + .slider {
  background-color: #137cbd;
}

input:focus + .slider {
  box-shadow: 0 0 1px #137cbd;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

/*Removes cross in Input BOX*/
input::-ms-clear {
    display: none;
}